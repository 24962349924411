jQuery( document ).ready(function($) {

  AOS.init({
    duration: 1000
  });

  // Manifesto Slider
  $('.manifesto-slider').slick({
    slidesToShow: 3,
    arrows: false,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          centerMode: true,
          centerPadding: '40px',
          slidesToShow: 1
        }
      }
    ]
  });
  
  function keyPress (e) {
    if(e.key === "Escape") {
        console.log('escape')
    }
  }

  // Manifesto popup
  const manifestoImages = document.querySelectorAll('.manifesto-slide')
  const popupHandler = (e) => {
    const currentImage = e.currentTarget
    const currentSrc = currentImage.getAttribute('src')
    const currentId = currentImage.getAttribute('imageId')
    const popupImage = document.querySelector('.manifesto-popup img')

    document.querySelector('.manifesto-popup').classList.add('active')
    popupImage.setAttribute('src', currentSrc)
    popupImage.setAttribute('imageId', currentId)

    document.addEventListener('keydown', (e) => {
      if (e.key === 'Escape'){
        document.querySelector('.manifesto-popup').classList.remove('active')
      }
    })

    document.querySelector('.manifesto-popup').addEventListener('click', (e) => {
      if (!e.path[0].classList.contains('keep')) {
        document.querySelector('.manifesto-popup').classList.remove('active')
      }
    })
  }

  manifestoImages.forEach(image => image.addEventListener('click', popupHandler))
  document.querySelector('.close').addEventListener('click', () => {
    document.querySelector('.manifesto-popup').classList.remove('active')
  })

  document.querySelector('.prev').addEventListener('click', (e) => {
    const currentImage = document.querySelector('.manifesto-popup img')
    const currentImageId = currentImage.getAttribute('imageId')
    let nextImageId = parseInt(currentImageId) - 1

    if (currentImageId == 1){
      nextImageId = 3
    }
    const nextImageSrc = document.querySelector(`.manifesto-slider .image-${nextImageId}`).getAttribute('src')
    currentImage.setAttribute('imageId', nextImageId)
    currentImage.setAttribute('src', nextImageSrc)
  })
  document.querySelector('.next').addEventListener('click', (e) => {
    const currentImage = document.querySelector('.manifesto-popup img')
    const currentImageId = currentImage.getAttribute('imageId')
    let nextImageId = parseInt(currentImageId) + 1
    
    if (currentImageId == 3){
      nextImageId = 1
    }
    
    const nextImageSrc = document.querySelector(`.manifesto-slider .image-${nextImageId}`).getAttribute('src')
    currentImage.setAttribute('imageId', nextImageId)
    currentImage.setAttribute('src', nextImageSrc)
  })

  // Player
  const audio = document.querySelector('.song audio')
  const allTracks = document.querySelectorAll('.track')
  const tracklistLength = allTracks.length
  const buttonPlay = document.querySelector('.play')
  const buttonPause = document.querySelector('.pause')
  
  const tracklistHandler = (e) => {
      e.preventDefault()
      const nextSongId = parseInt(e.currentTarget.getAttribute('songId'))
      playThis(nextSongId)
      tracklistTracks.classList.toggle('active')
  }
  
  const playThis = (id) => {
      // get song elements
      const song = document.querySelector('.song')
      let SongNumber = song.querySelector('.current-track-number')
      let SongName = song.querySelector('.name')
      let SongSource = song.querySelector('audio source')

      // get track values
      const nextTrack = document.querySelector(`[songId="${id}"]`)
      const nextTrackId = nextTrack.getAttribute('songId')
      const nextTrackName = nextTrack.getAttribute('songName')
      const nextTrackPath = nextTrack.getAttribute('songPath')
      
      // update song elements
      song.setAttribute('song-id', nextTrackId)
      SongNumber.innerHTML = nextTrackId
      SongName.innerHTML = nextTrackName
      SongSource.src = nextTrackPath

      audio.load()
      audio.play()
  }

  buttonPlay.addEventListener('click', (e) => {
    e.preventDefault()
    audio.play()
  })

  buttonPause.addEventListener('click', (e) => {
    e.preventDefault()
    audio.pause()
  })

  allTracks.forEach(track => track.addEventListener('click', tracklistHandler))

  // Show Tracklist
  const tracklistTrigger = document.querySelector('.song .name')
  const tracklistTracks = document.querySelector('.tracklist')
  tracklistTrigger.addEventListener('click', () => {
      tracklistTracks.classList.toggle('active')
      function closeIt(e) {
        if (!e.path[0].classList.contains('name')){
          tracklistTracks.classList.remove('active')
          document.removeEventListener('click', closeIt)
        }
      }
      document.addEventListener('click', closeIt)
  })
  document.querySelector('.tracklist').addEventListener('click', () => {
    document.querySelector('.tracks').classList.toggle('active')
    function closeIt(e) {
      if (!e.path[0].classList.contains('tracklist')){
        document.querySelector('.tracks').classList.remove('active')
        document.removeEventListener('click', closeIt)
      }
    }
    document.addEventListener('click', closeIt)
  })

  // Stick when top
  const stickThis = document.querySelector(".player");
  const startPosition = stickThis.getBoundingClientRect().top + window.pageYOffset;
  window.onscroll = function() {
      if (window.pageYOffset > startPosition) {
          stickThis.classList.add('sticked');
      } else {
          stickThis.classList.remove('sticked');
      }
  }

  document.querySelector('.manifesto-current').addEventListener('click', () => {
    document.querySelector('.image').classList.toggle('zoomed')
  })
  
});